<template>
  <div class="index-page">
    <div v-loading="loading" class="loading"></div>
  </div>
</template>

<script>
import { 
  CLOUDDISK_GET_USERCENTER,
  GET_ENCRYPTSTRING_KSEDU
} from '@/http/api.js';
export default {
  name: 'Index',
  props: [],
  components: {},
  data() {
    return {
      loading: true, // loading
      code: '', // 地址栏参数
      state: '', // 类型 1 -> 平板 2 -> windows 3 -> 跳转页面

      result: '0',
      error: ''
    };
  },
  watch: {},
  computed: {},
  methods: {
    getRequestParamValue(name){
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
      var ret = regex.exec(location.search);
      return ret == null ? "" : decodeURIComponent(ret[1].replace(/\+/g, " "));
    },
    async onInitCode() {
      this.code = this.getRequestParamValue('Code');
      this.state = this.getRequestParamValue('state');
      if(this.isValid(this.code) && this.code.length > 0) {
        let res = await CLOUDDISK_GET_USERCENTER({ code: this.code });
        if(res.data.ReturnCode == 1) {
          let result = res.data.result;
          this.onGoUrl(result[0], Number(this.state));
        } else {
          this.$message.error('Code换取session失败');
          location.href = `https://sso.ksedu.cn/SignInSmall.aspx?ReturnUrl=${location.origin}${location.pathname}?state=${this.state}`
        }
      } else {
        let time = parseInt(new Date().getTime() / 1000);
        let res = await GET_ENCRYPTSTRING_KSEDU({ timespan: time });
        if(res.data.ReturnCode == 1) {
          let result = res.data.result;
          this.onGoRedirect(result[0], time, this.state)
        }
      }
    },
    onGoUrl(session, state) {
      if(state == 3) {
        this.$router.push({ path: '/CloudDisk/Market', query: { session } });
      } else {
        let count = 0;
        let timer = setInterval(() => {
          if(count <= 30) {
            count++;
            if(state == 1) {
              HostApp.alert({ "cmd": "Sessionlogin", "session": session });
            } else if(state == 2) {
              window.CallCPP('<WebLogin result="' + this.result + '" session="' + session + '" error="' + this.error + '"></WebLogin>');
            }
            clearInterval(timer);
          } else {
            console.log("重刷次数超限");
            location.href = `https://sso.ksedu.cn/SignInSmall.aspx?ReturnUrl=${location.origin}${location.pathname}?state=${state}`
          }
        }, 200);
      }
    },
    onGoRedirect(encrypt, time, state) {
      location.href = `https://sso.ksedu.cn/Authorization.aspx?AppCode=CBClass&EncryptString=${encrypt}&TimeSpan=${time}&ReturnUrl=${location.origin}${location.pathname}?state=${state}`
    }
  },
  created() {},
  mounted() {
    this.onInitCode();
  }
};
</script>

<style lang="scss" scoped>
.index-page {
  width: 100vw;
  height: 100vh;
  .loading {
    width: 100%;
    height: 100%;
  }
}
</style>
